import { isNumber as _isNumber, isString as _isString } from 'lodash';

import { LanguageInterface } from './language.interface';
import { LanguageTypeEnum } from './language-type.enum';

export class Language implements LanguageInterface {
  static readonly ALL_ID: number = 0;
  static readonly ALL_VALUE: string = '*';
  static readonly ENGLISH_ID: number = 15;
  static readonly ENGLISH_VALUE: string = 'en';
  static readonly JAPANESE_ID: number = 27;
  static readonly JAPANESE_VALUE: string = 'ja';
  static readonly RTL_VALUES: string[] = ['ar', 'he', 'ur'];
  static readonly RTL_ID: number[] = [2, 22, 47];
  static readonly LOCALE_POSITION: number = 0;
  static readonly CHINESE_VALUES: string[] = ['zh-tw', 'zh-zh', 'zh-hk'];
  static readonly CHINESE_ID: number[] = [67, 56, 8];

  static readonly CHINESE_MESSAGES: string[] = [
    'Chinese (Traditional) = zh-Hant',
    'Chinese (Simplified) = zh-Hans',
    'Chinese (Trad.) Hong Kong = zh-HK'
  ];

  protected _languageType: LanguageTypeEnum;
  protected _id: number;
  protected _name: string;
  protected _alternateName: string;
  protected _iso6391: string;
  protected _localeLanguage: string;

  constructor(
    id: number, name: string, alternateName: string, localeLanguage: string, languageType: LanguageTypeEnum, iso6391: string = ''
  ) {
    this._id = id;
    this._name = name;
    this._alternateName = alternateName;
    this._localeLanguage = localeLanguage;
    this._languageType = languageType;
    this._iso6391 = iso6391;
  }

  /**
   * Indicates if the given language value is All.
   *
   * @param language number | Language | string
   * @returns boolean
   */
  static isAll(language: number | Language | string): boolean {
    return Language.is(language, Language.ALL_ID, Language.ALL_VALUE);
  }

  /**
   * Indicates if the given language value is English.
   *
   * @param language number | Language | string
   * @returns boolean
   */
  static isEnglish(language: number | Language | string): boolean {
    return Language.is(language, Language.ENGLISH_ID, Language.ENGLISH_VALUE);
  }

  /**
   * Indicates if the given language is a rtl one.
   *
   * @param language number | Language | string
   * @returns boolean
   */
  static isRtl(language: number | Language | string): boolean {
    const isIt: boolean = Language.RTL_ID.some(
      (rtlLanguageId: number, index: number) => Language.is(language, rtlLanguageId, Language.RTL_VALUES[index])
    );

    return isIt;
  }

  /**
   * Indicates if the given language is a rtl one.
   *
   * @param language number | Language | string
   * @returns boolean
   */
  static isChinese(language: number | Language | string): boolean {
    const isIt: boolean = Language.CHINESE_ID.some(
      (chineseLanguageId: number, index: number) => Language.is(language, chineseLanguageId, Language.RTL_VALUES[index])
    );

    return isIt;
  }

  /**
   * Get chinese Message.
   *
   * @param language number
   * @returns string
   */
  static getChineseMessage(language: number): string {
    const index: number = Language.CHINESE_ID.indexOf(language);

    if (index > -1) {
      return Language.CHINESE_MESSAGES[index];
    }

    return '';
  }

  /**
   * Indicates if the passed id is for Japanese
   *
   * @param id number
   * @returns boolean
   */
  static isJapanese(language: number | Language | string): boolean {
    return Language.is(language, Language.JAPANESE_ID, Language.JAPANESE_VALUE);
  }

  get code(): string {
    return this.localeLanguage;
  }

  get id(): number {
    return this._id;
  }

  get iso6391(): string {
    return this._iso6391;
  }

  get localeLanguage(): string {
    return this._localeLanguage;
  }

  get languageType(): LanguageTypeEnum {
    return this._languageType;
  }

  get name(): string {
    return this._name;
  }

  get alternateName(): string {
    return this._alternateName;
  }

  /**
   * Indicates if it is for "All".
   *
   * @returns boolean
   */
  isAll(): boolean {
    return Language.isAll(this.id);
  }

  /**
   * Indicates if it is for "English".
   *
   * @returns boolean
   */
  isEnglish(): boolean {
    return Language.isEnglish(this.id);
  }

  /**
   * Indicates if it is a rtl language.
   *
   * @returns boolean
   */
  isRtl(): boolean {
    return Language.isRtl(this.id);
  }

  toLowerCase() {
    return this.name.toLowerCase();
  }

  toString() {
    return this.name;
  }

  /**
   * Indicates if the given language matches with the expected ID or value.
   *
   * @param language number | string | Language
   * @param expectedId number
   * @param expectedValue string
   *
   * @returns boolean
   */
  protected static is(language: number | string | Language, expectedId: number, expectedValue: string): boolean {
    let isIt: boolean;

    if (_isNumber(language)) {
      isIt = language === expectedId;
    } else if (language instanceof Language) {
      isIt = (<Language>language).id === expectedId;
    } else if (_isString(language)) {
      isIt = language === expectedValue;
    } else {
      isIt = false;
    }

    return isIt;
  }
}
